import { NotFoundComponent } from '../../widgets/not-found/not-found.component';
// import { TravelPlanComponent } from '../../widgets/travel-plan/travel-plan.component';
// import { MembersListComponent } from '../../widgets/members-list/members-list.component';
// import {LamaScheduleComponent} from '../../widgets/lama-schedule/lama-schedule.component';
// import {MediaWithTextComponent} from '../../widgets/media-with-text/media-with-text.component';
import { ProjectsListComponent } from '../../widgets/projects-list/projects-list.component';
// import {CenterProjectsComponent} from '../../widgets/center-projects/center-projects.component';
// import {CenterContactComponent} from '../../widgets/center-contact/center-contact.component';
import imageDef from '../../widgets/image/image.def';
import videoDef from '../../widgets/video/video.def';
import quoteDef from '../../widgets/quote/quote.def';
import bookDef from '../../widgets/book/book.def';
import inspirationDef from '../../widgets/inspiration/inspiration.def';
// import exhibitionDef from '../../widgets/exhibition/exhibition.def';
import orgboardDef from '../../widgets/orgboard/orgboard.def';
import textDef from '../../widgets/text/text.def';
import widgetGrid from '../../widgets/widget-grid/widget-grid.def';
import childPages from '../../widgets/child-pages/child-pages.def';
import projectDonation from '../../widgets/project-donation/project-donation.def';
import headerGalleryDef from '../../widgets/header-gallery/header-gallery.def';
// import scHeroDef from '../../widgets/sc-hero/sc-hero.def';
// import scMeditationsScheduleDef from '../../widgets/sc-meditation-scheduler/sc-meditations-schedule.def';
// import scTeachingsProgramDef from '../../widgets/sc-teachings-program/sc-teachings-program.def';
import scProjectsTeaserDef from '../../widgets/sc-projects-teaser/sc-projects-teaser.def';
// import projectsSidebarDef from '../../widgets/projects-sidebar/projects-sidebar.def';
import streamingAwarenessDef from '../../widgets/streaming-awareness/streaming-awareness.def';
// import bannerDef from '../../widgets/banner/banner.def';
import radiusEventsDef from '../../widgets/radius-events/radius-events.def';
import prominentWidgetDef from '../../widgets/prominent-link/prominent-link.def';
// import paypalDonateDef from '../../widgets/paypal-donate/paypal-donate.def';
import onetimeEvents from '../../widgets/onetime-events/onetime-events.def';
import regularEvents from '../../widgets/regular-events/regular-events.def';
import googleFormsModalDef from '../../widgets/google-forms-modal/google-forms-modal.def';
import projectContactDef from '../../widgets/project-contact/project-contact.def';
import fileListDef from '../../widgets/file-list/file-list.def';
import creditCardFormDef from '../../widgets/credit-card-form/credit-card-form.def';
import peopleListDef from '../../widgets/people-list/people-list.def';
import iframeDef from '../../widgets/iframe/iframe.def';
// import mapDef from '../../widgets/map/map.def';
import menuDef from '../../widgets/menu/menu.def';
import newsFeedDef from '../../widgets/news-feed/news-feed.def';
// import updatesDef from '../../widgets/updates/updates.def';
import followButtonDef from '../../widgets/follow-button/follow-button.def';
import groupMembersListDef from '../../widgets/group-members-list/group-members-list.def';
import donationCounterDef from '../../widgets/donation-counter/donation-counter.def';
// import openJobsDef from '../../widgets/open-jobs/open-jobs.def';
import meditationTextsDef from '../../widgets/meditation-texts/meditation-texts.def';
import worldTourDef from '../../widgets/world-tour/world-tour.def';
import searchDef from "../../widgets/search/search.def";
// import {CoronaStatusComponent} from '../../widgets/corona-status/corona-status.component';
import { WIDGET_TYPE } from './consts/widget.consts';
import { IAppIcon } from '../model/app-icon.model';
import { IWidgetDefinition } from '../model/widget.model';
import { IFormDefinition } from '../model/formDefinition.model';
import postDef from '../../widgets/post/post.def';
import roomDef from '../../widgets/post/room.def';
import lamaLettersDef from '../../widgets/lama-letters/lama-letters.def';
import chronicleDef from '../../widgets/chronicle/chronicle.def';
import categoryDisplayDef from "../../widgets/category-display/category-display.def";

const dwcWidgetComponentMap = {
  post: postDef,
  room: roomDef,

  'prominent-link': prominentWidgetDef,
  'projects-list': { getComponent: () => ProjectsListComponent },
  image: imageDef,
  quote: quoteDef,
  'news-feed': newsFeedDef,
  search: searchDef,
  text: textDef,
  'widget-grid': widgetGrid,
  'child-pages': childPages,
  'people-list': peopleListDef,
  menu: menuDef,

  'credit-card-form': creditCardFormDef,
  'project-donation': projectDonation,
  'donation-counter': donationCounterDef,

  'follow-button': followButtonDef,
  administration: { icon: { style: 'fa-solid', name: 'gear' } },
  'members-list': { icon: { style: 'fa-solid', name: 'users-viewfinder' } }, // for now not addable via the ui - it needs a rewrite, if it should be accessable for editors
  'my-donations': {},
  'meditation-texts': meditationTextsDef,
  'group-members-list': groupMembersListDef,
  'google-forms-modal': googleFormsModalDef,
  'header-gallery': headerGalleryDef,
  'project-contact': projectContactDef,

  'world-tour': worldTourDef,
  chronicle: chronicleDef,
  'category-display': categoryDisplayDef,
  'centers-list': { icon: {style: "fa-solid", name: 'people-roof'} },
  'center-contact': {},
  'contact-list': {icon: {style: 'fa-solid', name: 'people-group'}},

  video: videoDef,
  'sc-projects-teaser': scProjectsTeaserDef,
  'post-detail': {},

  'onetime-events': onetimeEvents,
  'regular-events': regularEvents,
  'radius-events': radiusEventsDef,
  'streaming-awareness': streamingAwarenessDef,

  'file-list': fileListDef,

  iframe: iframeDef,
  
  /*
    'travel-plan': { component: TravelPlanComponent, icon: 'calendar-outline' },

    'sc-meditation-scheduler': scMeditationsScheduleDef,
    'sc-teachings-program': scTeachingsProgramDef,
    'open-jobs': openJobsDef,
    'sc-hero': scHeroDef,
    'center-projects': {component: CenterProjectsComponent},
    'center-contact': {component: CenterContactComponent},
    banner: bannerDef,
    'lama-schedule': {component: LamaScheduleComponent},
    'media-with-text': {component: MediaWithTextComponent},
    'projects-sidebar': projectsSidebarDef,
    'paypal-donate': paypalDonateDef,
    'corona-status': {component: CoronaStatusComponent},
    
    map: mapDef,
    updates: updatesDef,
    }

   */
} as Record<string, IWidgetDefinition>;

dwcWidgetComponentMap[WIDGET_TYPE.INSPIRATION] = inspirationDef;
dwcWidgetComponentMap[WIDGET_TYPE.LETTER] = lamaLettersDef;
dwcWidgetComponentMap[WIDGET_TYPE.ORG_BOARD] = orgboardDef;
dwcWidgetComponentMap[WIDGET_TYPE.BOOK] = bookDef;

/*
dwcWidgetComponentMap[WIDGET_TYPE.EXHIBITION] = exhibitionDef;

 */

/** a list of widgets, that can be added directly into any page */
export const coreWidgets = [
  'text',
  'image',
  'video',
  'prominent-link',
  //  'quote',
  'people-list',
  // 'widget-grid-filter', // removed, as there is no usecase anymore ;)
  'widget-grid',
  //    'projects-sidebar',
  'file-list',
  'menu',
  'regular-events',
  'onetime-events',
];
export const heroWidgets = [
  'header-gallery',
  'image',
  'video',
  'prominent-link',
];

/** @todo - not sure if those are really generic course widgets, might be only for the summer course */
export const courseWidgets = [
  'sc-teachings-program',
  'sc-meditation-scheduler',
  'sc-projects-teaser',
  'sc-inspiration-wall',
];

export const projectWidgets = ['credit-card-form', 'group-members-list'];

export async function getWidgetComponent(dwcWidgetType: string) {
  // easy straight foreward case
  if (
    dwcWidgetComponentMap[dwcWidgetType] &&
    typeof dwcWidgetComponentMap[dwcWidgetType].getComponent === 'function'
  ) {
    return dwcWidgetComponentMap[dwcWidgetType].getComponent();
  } else {
    let component;

    switch (dwcWidgetType) {
      case 'widget-editor':
      case 'administration':
        const { AdminModule } = await import(
          '../../modules/ondemand/admin.module'
        );
        component = AdminModule.getComponent(dwcWidgetType);
        break;
      case 'centers-list':
      case 'center-contact':
      case 'map':
        const {CentersModule} = await import('../../modules/ondemand/centers.module');
        component = CentersModule.getComponent(dwcWidgetType);
        break;
      case 'people-list':
      case 'members-list':
      case 'contact-list':
        const { MembersModule } = await import(
          '../../modules/ondemand/members.module'
        );
        component = MembersModule.getComponent(dwcWidgetType);
        break;
      case 'cloud-connection':
      case 'group-members-list':
      case 'sc-projects-teaser':
      case 'header-gallery':
      case 'category-display':
      case 'project-contact':
      case 'file-list':
        const { ProjectModule } = await import(
          '../../modules/ondemand/project.module'
        );
        component = ProjectModule.getComponent(dwcWidgetType);
        break;
      case 'my-donations':
      case 'credit-card-form':
      case 'project-donation':
      case 'donation-counter':
        const { PaymentModule } = await import(
          '../../modules/ondemand/payment.module'
        );
        component = PaymentModule.getComponent(dwcWidgetType);
        break;
      case 'streaming-awareness':
      case 'regular-events':
      case 'onetime-events':
      case 'radius-events':
        const { EventModule } = await import(
          '../../modules/ondemand/event.module'
        );
        component = EventModule.getComponent(dwcWidgetType);
        break;
      case 'meditation-texts':
        const { TravelingTeachersModule } = await import(
          '../../modules/ondemand/traveling-teachers.module'
        );
        component = TravelingTeachersModule.getComponent(dwcWidgetType);
        break;
      case 'google-forms-modal':
      case 'iframe':
        const { IFrameModule } = await import(
          '../../modules/ondemand/iframe.module'
        );
        component = IFrameModule.getComponent(dwcWidgetType);
        break;
      case 'chronicle':
      case 'world-tour':
        const { FiftyYearsModule } = await import(
          '../../modules/ondemand/fifty-years.module'
        );
        component = FiftyYearsModule.getComponent(dwcWidgetType);
        break;
      case 'book':
        const { BookModule } = await import('../../modules/ondemand/book.module');
        component = BookModule.getComponent(dwcWidgetType);
        break;
      case 'letter':
        const { LamaLettersModule } = await import('../../modules/ondemand/lama-letter.module');
        component = LamaLettersModule.getComponent(dwcWidgetType);
        break;
      case 'orgboard':
        const { CountryModule } = await import('../../modules/ondemand/country.module');
        component = CountryModule.getComponent(dwcWidgetType);
        break;
      case 'news-feed':
      case 'post-detail':
      case 'post':
        const { PostModule } = await import('../../modules/ondemand/post.module');
        component = PostModule.getComponent(dwcWidgetType);
        break;
      /*
            case 'beaufoy-grandhall-counter':
                const { BeaufoyModule } = await import('../../../../legacy/src/app/modules/ondemand/beaufoy.module');
                component = BeaufoyModule.getComponent(dwcWidgetType);
                break;
            case 'exhibition':
            case 'book':
                const { BookModule } = await import('../../../../legacy/src/app/modules/ondemand/book.module');
                component = BookModule.getComponent(dwcWidgetType);
                break;
            // case 'auction':
            //    const { AuctionModule } = await import('../../modules/ondemand/auction.module');
            //    component = AuctionModule.getComponent(dwcWidgetType);
            //    break;
            case 'sc-hero':
            case 'sc-projects-teaser':
            case 'sc-teachings-program':
            case 'sc-meditation-scheduler':
            case 'open-jobs':
                const { SummercourseModule } = await import('../../../../legacy/src/app/modules/ondemand/summercourse.module');
                component = SummercourseModule.getComponent(dwcWidgetType);
                break;
            case 'group-members-list':
            case 'header-gallery':
            case 'donation-counter':
            case 'paypal-donate':
                const {ProjectModule} = await import('../../../../legacy/src/app/modules/ondemand/project.module');
                component = ProjectModule.getComponent(dwcWidgetType);
                break;
            case 'centers-list':
            case 'map':
                const {CentersModule} = await import('../../../../legacy/src/app/modules/ondemand/centers.module');
                component = CentersModule.getComponent(dwcWidgetType);
                break;

           */
    }

    if (!component) {
      console.error(`Could not find component for ${dwcWidgetType}`);
    }

    // general fallback
    return component ? component : NotFoundComponent;
  }
}

export function getWidgetIcon(dwcWidgetType: string): IAppIcon {
  if (
    dwcWidgetComponentMap[dwcWidgetType] &&
    dwcWidgetComponentMap[dwcWidgetType].icon
  ) {
    return dwcWidgetComponentMap[dwcWidgetType].icon;
  }

  return { style: 'fa-regular', name: 'circle-question' };
}

export function getWidgetIdsByCategory(category: string) {
  return Object.keys(dwcWidgetComponentMap).filter((dwcWidgetType) => {
    return (
      dwcWidgetComponentMap[dwcWidgetType] &&
      dwcWidgetComponentMap[dwcWidgetType].category === category
    );
  });
}

export function getWidgetBackendOnlyFields(dwcWidgetType: string) {
  /* @todo - something strange is going on here - verify if this is needed at all, if a backend only field is used (credit card)
  if (dwcWidgetComponentMap[dwcWidgetType] && dwcWidgetComponentMap[dwcWidgetType].backendOnlyFields) {
        return dwcWidgetComponentMap[dwcWidgetType].backendOnlyFields;
    }
*/
  return null;
}

export function getWidgetFields(dwcWidgetType: string): IFormDefinition[] {
  if (
    dwcWidgetComponentMap[dwcWidgetType] &&
    dwcWidgetComponentMap[dwcWidgetType].fields
  ) {
    return dwcWidgetComponentMap[dwcWidgetType].fields;
  }

  return [{ id: 'title', input: 'text' }];
}
