import {IWidgetDefinition} from '../../core/model/widget.model';

export default ({
        category: 'content',
        icon: { style: 'fa-regular', name: 'clipboard'},
    fields: [
        {
            id: 'src',
            label: 'Iframe Src Url',
            input: 'text',
            required: true
        },
        {
            id: 'aspectRatio',
            label: 'Aspect Ration of the iFrame (Height divided by Width)',
            input: 'number',
            required: true,
            default: 1
        },
        {
            id: 'parameters',
            label: 'Dynamic GET Parameters',
            input: 'complex-list',
            subtype: [
                {
                    id: 'param',
                    label: 'Prefill Parameter Name (eg. firstName)',
                    input: 'text'
                },
                {
                    id: 'userProperty',
                    label: 'Property name of the user for data prefill',
                    input: 'text',
                    description: 'You have access to the complete user object. For example: name, email, id, url'
                }
            ]
        }
    ]
} as IWidgetDefinition)
