import {IWidgetDefinition} from '../../core/model/widget.model';
import {SearchDetailComponent} from './search-detail.component';

export default ({
    getComponent: () => SearchDetailComponent,
    icon: {style: 'fa-solid', name: 'magnifying-glass'},
    fields: [
        {
            id: 'lamaImpressionParents',
            label: 'Lame Impression Parent Config',
            description: 'Json array of parent types and ids, regarded as Lama Impressions tiles. Example: [{"type": "page", "id": 764}, {...}]',
            input: 'text',
            required: true,
        },

        {
            id: 'lamaLetterParents',
            label: 'Lame Letter Parent Config',
            description: 'Json array of parent types and ids, regarded as Lama Impressions tiles. Example: [{"type": "page", "id": 764}]',
            input: 'text',
            required: true,
        },

    ]
} as IWidgetDefinition)
