import { Injectable } from '@angular/core';
import {TranslationHelper} from "./translation.helper";
import {RoutingHelper} from './routing.helper';
import { IPost } from '../model/post.model';
import { IProject } from '../model/project.model';
import { IWidget } from '../model/widget.model';
import { ICenter } from '../model/center.model';
import {IPage} from "../model/page.model";
import {VideoHelperService} from '../services/video-helper.service';
import {cloneDeep} from 'lodash-es';
import {ProjectService} from "../services/project.service";
import {CenterService} from "../services/center.service";
import {PageService} from "../services/cms/page.service";
import {WidgetService} from "../services/cms/widget.service";

@Injectable({
  providedIn: 'root'
})

export class PostHelper {

  constructor(
    private translationHelper: TranslationHelper,
    private routingHelper: RoutingHelper,
    private videoHelper: VideoHelperService,
    private projectService: ProjectService,
    private centerService: CenterService,
    private pageService: PageService,
    private widgetService: WidgetService,
  ) {
  }

  prepareForPresentation(post: IPost) : IPost {
    post = cloneDeep(post);

    // add the permalink
    post.route = this.routingHelper.getPostDetailRoute(
      post,
      this.translationHelper.getBestLanguageValue(post.title, 'en')
    );

    // normalize the parent data
    post.parentRoute = this.getParentRoute(post.parentType, post.parent)
    post.parentTitle = this.getParentTitle(post.parentType, post.parent)

    // so we can reliably use toLocaleDateString in the view
    if (post.created && typeof post.created === 'string') {
      post.created = new Date(post.created);
    }
    if (post.lastModified && typeof post.lastModified === 'string') {
      post.lastModified = new Date(post.lastModified);
    }

    if (!post.data) {
      post.data = {};
    }

    post.data.title = post.title;
    post.data.route = post.route;
    post.data.content = post.content;
    post.data.created = post.created;
    post.data.lastModified = post.lastModified;
    post.data.images = post.images;
    post.data.categories = post.categories;

    // is it a video?
    if (post.data?.includeVideo) {
      post.images = [{
        image: this.videoHelper.getVideoCover({
          type: post.data.videoType,
          src: post.data.videoSrc,
          cover: post.data.videoCover,
          m3u8: post.data.videoM3u8
        })
      }];
    }

    return post;
  }

  async getParent (parentType: string, parentId: string) {
    switch (parentType.toLowerCase()) {
      default:
        throw new Error("Can not find entity of type " + parentType);
      case 'project':
        return await this.projectService.getProject(parseInt(parentId, 10));
      case 'widget':
        return await this.widgetService.getWidget(parseInt(parentId, 10));
      case 'center':
        return await this.centerService.getCenterById(parentId);
      case 'page':
        return await this.pageService.getPageById(parseInt(parentId, 10));
    }

    return null;
  }

  getParentRoute(parentType: string, parent: IProject | IWidget | ICenter | IPage): string {
    switch (parentType.toLowerCase()) {
      default:
        throw new Error("Can not find route of " + parentType);
      case 'project':
        return this.routingHelper.getProjectRoute(parent as IProject);
      case 'widget':
        return (parent as IWidget)?.pages[0]?.route;
      case 'center':
        return this.routingHelper.getCenterRoute(parent as ICenter);
      case 'page':
        return (parent as IPage)?.route;
    }
  }

  public getParentTitle(parentType: string, parent: IProject | IWidget | ICenter | IPage): string {
    switch (parentType.toLowerCase()) {
      default:
        throw new Error("Can not find parent of " + parentType);
      case 'project':
        return (parent as IProject)?.name;
      case 'widget':
        return (parent as IWidget)?.pages[0]?.title;
      case 'center':
        return (parent as ICenter)?.displayName;
      case 'page':
        return (parent as IPage)?.title;
    }
  }
}

