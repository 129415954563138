import {Injectable} from "@angular/core";
import {StorageMap} from "@ngx-pwa/local-storage";
import {EnvironmentService} from "../services/environment.service";
import {firstValueFrom} from "rxjs";
import gql from "graphql-tag";

@Injectable({
  providedIn: 'root'
})
export class Oauth2Service {

  refreshToken: string|null = null;
  accessToken: string|null = null;
  tokenExpiresAt: Date|null = null;

  tokenResolves = []

  redirecting = false;
  resolveInProgress = false;

  constructor(
    private storage: StorageMap,
    private environmentService: EnvironmentService
  ) {
  }

  public async getValidToken() : Promise<string> {

    return new Promise((resolve, reject) => {
      if (this.redirecting) {
        return resolve(null);
      }

      this.tokenResolves.push(resolve);

      if (!this.resolveInProgress) {
        this.resolveInProgress = true;
        this.retrieveAccessToken();
      }
    })
  }

  private async retrieveAccessToken() {
    // do we have data in the url?
    if (
      location.hash &&
      location.hash.length > 7 &&
      location.hash.indexOf('action-') < 0 &&
      location.hash.indexOf('anchor-') < 0
    ) {
      const urlHashParams : Record<string, string> = {};

      location.hash.substring(1).split('&').forEach(kvp => {
        const parts = kvp.split('=')
        urlHashParams[parts[0]] = decodeURIComponent(parts[1]);
      });

      // clear the hash
      location.hash = '';

      // set the access token and other values
      await this.updateAccessToken(urlHashParams['access_token'], urlHashParams['token_expires_at'], urlHashParams['refresh_token']);

      // test the nonce, clear everything in case of mismatch
      if (urlHashParams['nonce'] !== await this.getExpectedNonce()) {
        console.error('Invalid Nonce! Show "Login Again" error');
        await this.clearAuth();
        await this.signIn();
      }

      console.log('Initial Login with access token %o and refresh token %o', this.accessToken, this.refreshToken);
    }

    const now = new Date();

    // are we still logged in from before?
    if (!this.accessToken) {

      const access = await firstValueFrom(this.storage.get('access')) as {accessToken: string, tokenExpiresAt: string, refreshToken: string};

      console.log('Access from local storage', access);

      if (access && access.tokenExpiresAt) {
        this.refreshToken = access.refreshToken;
        this.accessToken = access.accessToken;
        const tokenExpiresAt = access.tokenExpiresAt;

        if (typeof tokenExpiresAt === 'string') {
          this.tokenExpiresAt = new Date(tokenExpiresAt);
        }

        console.log('found existing token %o and refresh token %o', this.accessToken, this.refreshToken);
      }
    }

    if ((!this.accessToken || !this.tokenExpiresAt) && !this.refreshToken) {
      // we are missing basic values, so we need to sign in again
      await this.signIn();
    }

    let validTokenOrNull = this.accessToken && this.tokenExpiresAt && now.getTime() < this.tokenExpiresAt.getTime() ? this.accessToken : null;

    if (!validTokenOrNull && this.refreshToken) {
      const refreshResponse = await fetch(this.environmentService.currentEnv.backendBasePath + '/login/frontend-refresh', {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'X-REFRESH-TOKEN': this.refreshToken,
        }
      });

      const res = await refreshResponse.json();
      await this.updateAccessToken(res.accessToken, res.expiresAt, res.refreshToken);
      console.log('Got new Token: ' + res.accessToken);

      validTokenOrNull = res.accessToken;
    }

    this.tokenResolves.forEach(resolve => {
      resolve(validTokenOrNull);
    });
    this.tokenResolves = [];
    this.resolveInProgress = false;
  }

  private base64UrlEncode (str: string) {
    const base64 = btoa(str);
    return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
  }

  private createNonce() {
    /*
     * This alphabet is from:
     * https://tools.ietf.org/html/rfc7636#section-4.1
     *
     * [A-Z] / [a-z] / [0-9] / "-" / "." / "_" / "~"
     */
    const unreserved = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    let size = 45;
    let id = '';
    // @ts-ignore
    const crypto = typeof self === 'undefined' ? null : self.crypto || self['msCrypto'];
    if (crypto) {
      let bytes = new Uint8Array(size);
      crypto.getRandomValues(bytes);
      // Needed for IE
      if (!bytes.map) {
        // @ts-ignore
        bytes.map = Array.prototype.map;
      }
      bytes = bytes.map((x) => unreserved.charCodeAt(x % unreserved.length));
      // @ts-ignore
      id = String.fromCharCode.apply(null, bytes);
    }
    else {
      while (0 < size--) {
        id += unreserved[(Math.random() * unreserved.length) | 0];
      }
    }
    return this.base64UrlEncode(id);
  }

  public async createAndSaveNonce() {
    const nonce = this.createNonce();
    await firstValueFrom(this.storage.set('nonce', nonce));

    return nonce;
  }

  public async getExpectedNonce() : Promise<string> {
    return await firstValueFrom(this.storage.get('nonce')) as string;
  }


  public async updateAccessToken(accessToken: string, tokenExpiresAt: string, refreshToken: string = null) {
    if (accessToken && tokenExpiresAt) {
      // beware of the async nature of those functions! Set them first to the class, so that following process can work normally
      this.accessToken = accessToken;
      this.tokenExpiresAt = new Date(tokenExpiresAt);

      const access = (await firstValueFrom(this.storage.get('access')) || {}) as {accessToken: string, tokenExpiresAt: string, refreshToken: string};

      access.accessToken = accessToken;
      access.tokenExpiresAt = tokenExpiresAt;

      if (refreshToken) {
        access.refreshToken = refreshToken;
        this.refreshToken = refreshToken;
      }

      await firstValueFrom(this.storage.set('access', access ));
    }
  }

  async clearAuth() {
    await firstValueFrom(this.storage.delete('access' ));
    await firstValueFrom(this.storage.delete('nonce'));
  }

  async signIn() {
    if (!this.redirecting) {
      console.log('Triggering a new signin')
      this.redirecting = true;

      const backendLoginUrl = new URL(this.environmentService.currentEnv.backendBasePath + '/login/frontend-init');
      const nonce = await this.createAndSaveNonce();
      backendLoginUrl.searchParams.set('next', window.location.href);
      backendLoginUrl.searchParams.set('nonce', nonce);

      window.location.href = backendLoginUrl.toString();
    }
  }

  async signOut() {
    await this.clearAuth();

    const logoutUrl = new URL(this.environmentService.currentEnv.ssoPath + '/accounts/logout/');
    logoutUrl.searchParams.set('next', window.location.href);

    window.location.href = logoutUrl.toString();
  }
}
