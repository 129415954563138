<ng-container [ngSwitch]="display">

  <div *ngSwitchCase="'preview'" class="inspiration-wall__image" title="{{translationHelper.getBestLanguageValue(data.title)}}" (click)="openModal(id)">
    <div>
      <app-img *ngIf="data.images.length"
               [image]="data.images[0].image"
               [colSizes]="colSizes"
               [aspectRatio]="0.75"
               [smartCrop]="true"
               [roundedCorners]="true" >
      </app-img>

      <img *ngIf="!data.images.length" ngSrc="/assets/imgs/nophoto_center.png" class="inspiration-wall__img"
           height="140" width="300"/>
    </div>

    <a *ngIf="data.event" class="d-flex align-items-center" [routerLink]="routingHelper.getCenterRouteBySlug(data.event.organizingCenter?.slug)">
      <i class="fa-solid fa-location-dot"></i>

      <div>
        <h5 *ngIf="data.location">{{ data.location }}</h5>
        <p>{{ ('country.' + data.event.location.addressCountry) | translate }}</p>
      </div>
    </a>
  </div>

  <div *ngSwitchCase="'slide'" class="events-slider__slide-box">
    <div class="events-slider__slide-image"
         [class.events-slider__slide-image--title]="title"
         [routerLink]="data.slide?.route"
         (click)="data.slide?.route ? null : openModal(id)"
         title="{{title}}"
    >
      <app-img *ngIf="data.images?.length"
               [image]="data.images[0].image"
               [aspectRatio]="1.5"
               width="200px"
               [smartCrop]="true"
               [roundedCorners]="true"
               align="alone"
      ></app-img>
      <div *ngIf="title" class="events-slider__slide-event-title">
        <h5>{{ title.substring(0, 55) + (title.length < 55 ? '' : '...')  }}</h5>
      </div>
    </div>

    <a *ngIf="data.slide" class="d-flex align-items-center" [routerLink]="data.slide.location_route">
      <i class="fa-solid fa-location-dot"></i>

      <div>
        <h5 *ngIf="data.slide.location">{{ data.slide.location }}</h5>
        <p>{{ data.slide.sub_location }}</p>
      </div>
    </a>
  </div>

  <app-modal-frame *ngSwitchCase="'modal'" [showHeader]="false">

    <div slot="body" class="container-fluid">

      <div class="row align-items-stretch">
        <div class="col-md-8 bg-black">
          <div class="position-relative inspiration__slider-wrapper">

            <swiper-container appSwiper #slides [config]="sliderOptions" (slidechange)="updateSlideUi($event)">
              <swiper-slide *ngFor="let slide of slideObjects; let i = index" class="h-modal position-relative">
                <app-page-loader></app-page-loader>
                <div class="p-4 d-flex align-items-center h-modal">
                  <div class="h-75vh w-100 position-relative">
                    <img *ngIf="[-1, 0, 1].indexOf(this.currentSlide - i) > -1" [src]="imageProxy.getImageProxyUrl(slide.image.image?.src, '', 1200)" class="inspiration__image"/>
                  </div>
                </div>
              </swiper-slide>
            </swiper-container>

            <button *ngIf="!disablePrevBtn" type="button" class="btn btn-lg btn-dark text-white position-absolute top-50 start-0 translate-middle-y rounded-circle z-3 inspiration__button" (click)="prevSlide()">
              <i class="fa-solid fa-chevron-left"></i>
            </button>
            <button *ngIf="!disableNextBtn" type="button" class="btn btn-lg btn-dark text-white position-absolute top-50 end-0 translate-middle-y rounded-circle z-3 inspiration__button" (click)="nextSlide()">
              <i class="fa-solid fa-chevron-right"></i>
            </button>

          </div>
        </div>
        <div class="col-md-4 bg-white inspiration__info-col">

          <div class="px-4 py-5">
            <h2>{{translationHelper.getBestLanguageValue(data.title)}}</h2>
            <p *ngIf="data.location" class="fs-7 text-gray">
              <i class="fa-solid fa-location-dot me-1"></i>
              {{data.location}}<span *ngIf="data.event">, {{ ('country.' + data.event.location.addressCountry) | translate }}</span>
            </p>
            <h3 *ngIf="translationHelper.getBestLanguageValue(data.headline)">{{translationHelper.getBestLanguageValue(data.headline)}}</h3>
            <div *ngIf="translationHelper.getBestLanguageValue(data.description)" class="inspiration-preview__desc" [innerHTML]="translationHelper.getBestLanguageValue(data.description)"></div>
            <button type="button" class="btn btn-link btn-link--secondary" (click)="modalService.dismiss()">
              <i class="fa-solid fa-chevron-left fs-7"></i> Go back
            </button>
          </div>
        </div>
      </div>

      <button type="button" class="btn btn-link text-white position-absolute top-0 start-0 p-3 z-3" (click)="modalService.dismiss()"><i class="fa-solid fa-chevron-left fs-7"></i> Go back</button>

    </div>
  </app-modal-frame>
</ng-container>
