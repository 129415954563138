<nav class="navbar navbar-expand-lg fixed-top bg-body">
  <div class="container">
    <a class="navbar-brand d-flex align-items-center fs-6" [routerLink]="menu?.page?.route">
      <img src="/assets/imgs/firewheel.png" alt="Diamond Way Connect" class="menu__logo d-inline-block align-text-top me-2">
      <strong>Diamond Way Connect</strong>
    </a>

    <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#mainMenu" aria-controls="mainMenu" (click)="c.toggle()" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse justify-content-between" id="mainMenu" #c="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <span><!-- This one is here, so the navbar aligns automatically in the center, not in the left --></span>
      <ul class="navbar-nav">
        <li *ngFor="let item of menu?.children" class="nav-item">
          <a class="nav-link d-flex align-items-center" [routerLink]="item.page.route" [routerLinkActive]="['active']">
            {{item.label.en}}
            <span *ngIf="shouldDisplayBatch(item, 'contactRequestCount')" class="border border-primary text-primary rounded-circle badge badge--circle ms-1">{{contactRequestCount}}</span>
            <span *ngIf="shouldDisplayBatch(item, 'projectInvitationCount')" class="border border-primary text-primary rounded-circle badge badge--circle ms-1">{{projectInvitationCount}}</span>
          </a>
        </li>
      </ul>

      <div class="d-flex align-items-center">
        <app-search class="d-inline-block me-3"></app-search>

        <div ngbDropdown class="d-inline-block me-3" #appDrop="ngbDropdown" (mouseenter)="appDrop.open()" (mouseleave)="appDrop.close()">
          <button type="button" class="profile-btn" title="Click to open" ngbDropdownToggle>
            <img alt="apps" src="/assets/imgs/MenuIcon.svg" tabindex="0">
          </button>

          <ul ngbDropdownMenu class="dropdown-menu-end mt-2">
            <li class="dropdown-menu__mousecatch"></li>
            <li *ngFor="let apps of menuOptions">
              <a href="{{ apps.link.href }}" class="dropdown-item" target="_blank">{{ apps.link.title }}</a>
            </li>
          </ul>

        </div>

        <div ngbDropdown class="d-inline-block" #profileDrop="ngbDropdown" (mouseenter)="profileDrop.open()" (mouseleave)="profileDrop.close()">
          <button type="button" class="profile-btn" title="Click to open" ngbDropdownToggle>
            <img alt="my profile" class="profile-btn__image" src="{{avatar}}"/>
            <span *ngIf="batchCount > 0" class="border border-primary text-primary rounded-circle badge badge--circle ms-1 bg-white">{{batchCount}}</span>
          </button>

          <ul ngbDropdownMenu class="dropdown-menu-end mt-2">
            <li class="dropdown-menu__mousecatch"></li>
            <li *ngFor="let pmp of profileMenuPoints">
              <a class="dropdown-item d-flex align-items-center" [routerLink]="pmp.route" [routerLinkActive]="['active']">{{pmp.label}} <span *ngIf="pmp.batchCount && pmp.batchCount > 0" class="border border-primary text-primary rounded-circle badge badge--circle ms-1 bg-white">{{pmp.batchCount}}</span></a>
            </li>
            <li>
              <a class="dropdown-item cursor-pointer" (click)="auth.signOut()">{{ 'logout' | translate }}</a>
            </li>
          </ul>
        </div>
      </div>

    </div>



  </div>
</nav>
