<div class="text-gray d-flex align-items-center">
  <svg xmlns="http://www.w3.org/2000/svg" class="w-2 h-2 cursor-pointer" viewBox="0 0 20 20" fill="currentColor"
       (click)="expandSearch()">
    <path fill-rule="evenodd"
          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
          clip-rule="evenodd"/>
  </svg>

  <div ngbDropdown #searchDropdown="ngbDropdown" (mouseenter)="displayResults ? searchDropdown.open() : null" (mouseleave)="!loading ? searchDropdown.close() : null">

    <input #search class="line__input search__input fs-6" [ngClass]="{'search__input--expanded': searchIsExpanded}" placeholder="Search..."
           (input)="searchUpdated.next(search.value.toString().trim().toLocaleLowerCase())" (blur)="onClickOutside()" (keyup)="handleEnter($event)"/>

    <ul ngbDropdownMenu class="dropdown-menu-end mt-2">
      <li class="dropdown-menu__mousecatch"></li>
      <li *ngIf="loading">
        <span class="dropdown-item">Loading...</span>
      </li>
      <li *ngIf="!loading && showMinLength">
        <span class="dropdown-item">Please type at least 3 characters</span>
      </li>
      <li *ngIf="!loading && !showMinLength && !searchResults?.total">
        <span class="dropdown-item">No Results</span>
      </li>

      <ng-container *ngIf="!loading">

        <ng-container *ngIf="searchResults?.content.found > 0">
          <li class="dropdown-item"><strong>{{getContentHeadlineFromFacets(searchResults.content.facet_counts)}}:</strong></li>
          <li *ngFor="let hit of searchResults.content.hits">
            <a [routerLink]="hit.document.route" class="dropdown-item">
              <span class="d-block" [innerHTML]="hit.highlight.title?.snippet || hit.document.title"></span>
              <div *ngIf="hit.highlight.content" class="fs-7 text-gray"
                   [innerHTML]="getCleanSnippet(hit.highlight.content.snippet)">
              </div>
            </a>
          </li>
          <ng-container *ngTemplateOutlet="moreLink;context:{count: searchResults?.content.found - searchResults.content.hits.length}"></ng-container>
        </ng-container>

        <ng-container *ngIf="searchResults?.spaces.preview.length > 0">
          <li class="dropdown-item"><strong>Spaces:</strong></li>
          <li *ngFor="let space of searchResults.spaces.preview">
            <a href="{{space.href}}" (click)="browserService.navigateLink(space.href)" class="dropdown-item">
              {{ space.title }}
            </a>
          </li>
          <ng-container *ngTemplateOutlet="moreLink;context:{count: searchResults?.spaces.totalCount - searchResults?.spaces.preview.length}"></ng-container>
        </ng-container>
        <ng-container *ngIf="searchResults?.centers.preview.length > 0">
          <li class="dropdown-item"><strong>Centers:</strong></li>
          <li *ngFor="let center of searchResults.centers.preview">
            <a [routerLink]="routingHelper.getCenterRoute(center)" class="dropdown-item">
              <app-highlighted-string string="{{center.name}}" [toHighlight]="search.value.toString()"/>
            </a>
          </li>
          <ng-container *ngTemplateOutlet="moreLink;context:{count: searchResults?.centers.totalCount - searchResults?.centers.preview.length}"></ng-container>
        </ng-container>
        <ng-container *ngIf="searchResults?.countries.preview.length > 0">
          <li class="dropdown-item"><strong>Countries:</strong></li>
          <li *ngFor="let country of searchResults.countries.preview">
            <a [routerLink]="routingHelper.getCountryRouteByCode(country.code)" class="dropdown-item">
              <app-highlighted-string string="{{country.name}}" [toHighlight]="search.value.toString()"/>
            </a>
          </li>
          <ng-container *ngTemplateOutlet="moreLink;context:{count: searchResults?.countries.totalCount - searchResults?.countries.preview.length}"></ng-container>
        </ng-container>

        <ng-container *ngIf="searchResults?.members.preview.length > 0">
          <li class="dropdown-item"><strong>People:</strong></li>
          <li *ngFor="let member of searchResults.members.preview">
            <a [routerLink]="routingHelper.getMemberRoute(member)" class="dropdown-item">
              <app-highlighted-string string="{{member.givenName + ' ' + member.familyName}}" [toHighlight]="search.value.toString()"/>
            </a>
          </li>
          <ng-container *ngTemplateOutlet="moreLink;context:{count: searchResults?.members.totalCount - searchResults?.members.preview.length}"></ng-container>
        </ng-container>

      </ng-container>
    </ul>
  </div>
</div>

<ng-template #moreLink let-count="count">
  <li *ngIf="count > 0">
    <a routerLink="/search" [queryParams]="{q: searchUpdated.value}"  class="dropdown-item fs-7 text-primary">See {{count}} more</a>
  </li>
</ng-template>
