<div>
  <input #search class="form-control" placeholder="Search for anything" [defaultValue]="activeQuery.value" (input)="activeQuery.next(search.value.toString().trim())">
</div>

<div *ngIf="showResults" class="search__tags">

  <div class="mt-2 mb-5">
    <ng-container *ngFor="let filter of filters">

      <button *ngIf="filter.shouldDisplay()" (click)="activeFilter.next(activeFilter.value !== filter.ref ? filter.ref : ''); limitTo.next(null)" type="button" class="btn me-2 btn-sm" [ngClass]="{
      'btn-outline-primary': activeFilter.value !== filter.ref,
      'btn-primary': activeFilter.value === filter.ref
      }">
        {{ filter.label }}
      </button>
    </ng-container>
  </div>
</div>

<div *ngIf="showResults && !activeFilter.value" class="search__results">

  <div class="row my-3">

    <div *ngIf="impressionResult.found" class="col-6">
      <h2>
        {{impressionResult.found}} Photos from Lama Ole
        <button *ngIf="impressionResult.found > 1" (click)="activeFilter.next('impressions')" class="btn btn-link ms-2 fs-6">See all <i class="fa-solid fa-chevron-right ms-1 fs-7"></i></button>
      </h2>

      <a [routerLink]="impressionResult.hits[0].document.route" class="d-block">

        <ng-container *ngTemplateOutlet="image;context:{img: impressionResult.hits[0].document.cover}"></ng-container>

        <span>
          <span class="d-block h5 mb-0"><app-highlighted-string string="{{impressionResult.hits[0].document.title}}" toHighlight="{{activeQuery.value}}"/></span>
          <span class="text-gray"
                [innerHTML]="getSublineHtml(impressionResult.hits[0])">
          </span>
        </span>
      </a>

    </div>

    <div *ngIf="lettersResult.found" class="col-6">
      <h2>Lama Letters</h2>

      <div *ngFor="let hit of lettersResult.hits" class="mb-2">
        <a [routerLink]="hit.document.route">
          <span class="d-block h5 mb-0" [innerHTML]="hit.highlight.title?.snippet || hit.document.title"></span>
          <span class="text-gray"
               [innerHTML]="getSublineHtml(hit)">
          </span>
        </a>
      </div>
      <a *ngIf="lettersResult.found > 6" [routerLink]="[]" [queryParams]="{ref: 'letters', q: activeQuery.value}">See all {{lettersResult.found}}</a>
    </div>
  </div>

  <div *ngIf="centersResult.found" class="my-5">
    <h2>
      {{centersResult.found}} Centers
      <button *ngIf="centersResult.found > centersResult.hits.length" (click)="activeFilter.next('centers')" class="btn btn-link ms-2 fs-6">See all <i class="fa-solid fa-chevron-right ms-1 fs-7"></i></button>
    </h2>
    <div class="row">
      <ng-container *ngFor="let centerHit of centersResult.hits">
        <ng-container *ngTemplateOutlet="searchTile;context:{
          searchHit: centerHit
        }" ></ng-container>
      </ng-container>
    </div>
  </div>

  <div *ngIf="membersResult.found" class="my-5">
    <h2>
      {{membersResult.found}} People
      <button *ngIf="membersResult.found > membersResult.hits.length" (click)="activeFilter.next('people')" class="btn btn-link ms-2 fs-6">See all <i class="fa-solid fa-chevron-right ms-1 fs-7"></i></button>
    </h2>

    <div class="row">
      <ng-container *ngFor="let memberHit of membersResult.hits">
        <ng-container *ngTemplateOutlet="searchTile;context:{
          searchHit: memberHit
        }" ></ng-container>
      </ng-container>
    </div>
  </div>


  <div *ngIf="otherResult.found" class="my-5">
    <h2>
      {{otherResult.found}} in Everything else
      <button *ngIf="otherResult.found > otherResult.hits.length" (click)="limitTo.next(null); activeFilter.next('other')" class="btn btn-link ms-2 fs-6">See all <i class="fa-solid fa-chevron-right ms-1 fs-7"></i></button>
    </h2>

    <div *ngIf="parentFacets.length" class="my-3">
      <div class="row">
        <div *ngFor="let parentFacet of parentFacets" class="col-6 col-md-4 col-lg-3 mb-4">
          <a class="d-flex align-items-center cursor-pointer" (click)="limitTo.next({type: parentFacet.parentType, id: parentFacet.parentId, name: parentFacet.parentName}); activeFilter.next('other')">

            <span class="members-list__member me-2">
              <img [src]="(parentFacet.image?.src ? imageProxy.getImageProxyUrl(parentFacet.image?.src, 120, 120, false, null) : '/assets/imgs/nophoto_center.png')" class="members-list__member-image d-block"/>
            </span>

            <span class="members-list__content">
              <span class="d-block h5 mb-0">{{parentFacet.parentName}}</span>
                <span class="text-gray">{{parentFacet.count}} hits</span>
            </span>
          </a>
        </div>
      </div>
    </div>

    <div class="row">
      <ng-container *ngFor="let hit of otherResult.hits">
        <ng-container *ngTemplateOutlet="searchTile;context:{
          searchHit: hit
        }" ></ng-container>
      </ng-container>
    </div>
  </div>

</div>

<div *ngIf="showResults && activeFilter.value && detailResult?.found">
  <h2>{{limitTo.value ? limitTo.value.name : filtersByRef[activeFilter.value].label}} ({{detailResult?.found}})</h2>

  <div class="row">
    <ng-container *ngFor="let hit of detailResult.hits" >
      <ng-container *ngTemplateOutlet="searchTile;context:{
          searchHit: hit
        }" ></ng-container>
    </ng-container>
  </div>

</div>


<ng-template #image let-img="img" let-link="link">
  <app-img [image]="img" [colSizes]="{sm: 12, md: 6}" [aspectRatio]=".6" [roundedCorners]="true"></app-img>
</ng-template>

<ng-template #searchTile let-searchHit="searchHit">
  <div class="col-6 col-md-4 col-lg-3 mb-4">
    <a class="search__tile" href="{{searchHit.document.route}}" [routerLink]="searchHit.document.route">

      <app-img *ngIf="searchHit.document.cover"
               [image]="searchHit.document.cover"
               [colSizes]="{sm: 6, md: 4, lg: 3}"
               [aspectRatio]=".65"
               [roundedCorners]="true"
               align="alone"
      >
      </app-img>
      <div *ngIf="!searchHit.document.cover" class="search__placeholder-img search__placeholder-img--square"></div>

      <span class="d-block mt-2">
        <span class="d-block h5 mb-0"><app-highlighted-string string="{{searchHit.document.title}}" toHighlight="{{activeQuery.value}}"></app-highlighted-string></span>
        <span class="text-gray">
          <span [innerHTML]="getSublineHtml(searchHit)"></span>
        </span>
      </span>

    </a>
  </div>
</ng-template>
